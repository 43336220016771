













































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    EntitySelect,
  },
})
export default class HouseholdMemberPicker extends Vue {
  @Prop()
  public value!: any[];
}
