










































































































































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import draggable from "vuedraggable";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { Guid } from "guid-typescript";
import DatePicker from "@/components/DatePicker.vue";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import EntitySelect from "@/components/EntitySelect.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import FileInput from "@/components/FileInput.vue";
import HouseholdMemberPicker from "@/components/HouseholdMemberPicker.vue";
import { StrategicCommunication } from "@/data/models/StrategicCommunication";
@Component({
  components: {
    FormContainer,
    Loader,
    draggable,
    DatePicker,
    SubArrayForm,
    EntitySelect,
    FileInput,
    HouseholdMemberPicker
  }
})
export default class StrategicCommunicationsForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<StrategicCommunication>;

  @Prop()
  public crud!: ICrudClient<StrategicCommunication>;

  private enumForm = EnumForm;
  private item: any = null;
  private isAuthorityAppeal = false;

  private communicationTypes: any[] = [];
  private selectedCommunicationType: any = null;
  private metricValues = [];

  private async setFile(file: File, key: string) {
    if (this.item) {
      const res = await readFileAsDataUrlAsync(file);
      this.item[key] = {
        name: file.name,
        base64Contents: res
      };
    }
  }
  formTitle() {
    if (this.id == "new") {
      return `Add Strategic Communication`;
    }
    return `Edit Strategic Communication`;
  }
  async submit(v: any) {
    const itemToSubmit = JSON.parse(JSON.stringify(this.item));
    if (this.isAuthorityAppeal) {
      delete itemToSubmit.outreachRecords;
    } else {
      delete itemToSubmit.authorityAppeal;
      itemToSubmit.outreachRecords = itemToSubmit.outreachRecords.map(
        (o: any) => {
          const metricValues = Object.keys(o.metricValues).map(
            (metricId: any) => ({
              metricId: metricId,
              value: o.metricValues[metricId]
            })
          );
          o.metricValues = metricValues;
          return o;
        }
      );
    }
    itemToSubmit.attachments = itemToSubmit.attachments.map((a: any) => a.file);
    if (this.id == "new") {
      return (await this.crud.addAsync([itemToSubmit]))[0];
    } else {
      delete itemToSubmit.communicationType;
      return await this.crud.saveAsync(itemToSubmit);
    }
  }

  @Watch("isAuthorityAppeal")
  onIsAuthorityAppealChanged() {
    if (this.isAuthorityAppeal) {
      this.item.outreachRecords = [];

      if (!this.item.authorityAppeal) {
        this.item.authorityAppeal = {
          beneficiaryCase: false,
          response: false,
          submissionDate: new Date().toISOString(),
          beneficiaries: []
        };
      }
    } else if (!this.isAuthorityAppeal) {
      this.item.authorityAppeal = null;
      this.item.authorityAppealId = null;
      this.item.communicationType = null;
      this.item.communicationTypeId = null;
      this.selectedCommunicationType = null;
    }
  }

  @Watch("item")
  private onItemChanged(v: any) {
    if (this.id == "new") {
      return;
    }
    if (v.authorityAppeal != null) {
      this.isAuthorityAppeal = true;
    } else {
      this.selectedCommunicationType = v.communicationType;
      const outreachRecords = this.item.outreachRecords.map((or: any) => {
        const metricValuesMap = or.metricValues.reduce((m: any, mv: any) => {
          m[mv.metricId] = mv.value;
          return m;
        }, {});
        or.metricValues = metricValuesMap;
        return or;
      });
      this.item.outreachRecords = outreachRecords;
    }
    if (v.attachments != null) {
      this.item.attachments = v.attachments.map((a: any) => {
        a.file = { name: a.name };
        delete a.name;
        return a;
      });
    }
  }

  @Watch("selectedCommunicationType")
  onSelectedCommunicationTypeChanged() {
    if (!this.selectedCommunicationType) {
      this.item.outreachRecords = [];
      return;
    }
    if (this.item.outreachRecords && this.item.outreachRecords.length > 0) {
      return;
    }
    const id = Guid.create().toString();
    this.item.outreachRecords = [
      {
        id: id,
        startDate: null,
        endDate: null,
        metricValues: this.selectedCommunicationType.metrics.map((m: any) => ({
          metricId: m.id,
          outreachRecordId: id
        })),
        comments: ""
      }
    ];
    const outreachRecords = this.item.outreachRecords.map((or: any) => {
      const metricValuesMap = or.metricValues.reduce((m: any, mv: any) => {
        m[mv.metricId] = mv.value;
        return m;
      }, {});
      or.metricValues = metricValuesMap;
      return or;
    });
    this.item.outreachRecords = outreachRecords;
  }

  async mounted() {
    this.communicationTypes = (
      await this.$service.providers.communicationTypes.fetchItemsAsync()
    ).items;
    if (this.id === "new") {
      this.item = {
        id: Guid.create().toString(),
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        outreachRecords: [],
        attachments: [],
        authorityAppeal: {
          beneficiaryCase: false,
          response: false,
          submissionDate: new Date().toISOString(),
          beneficiaries: []
        },
        comments: ""
      };
    }
  }
}
